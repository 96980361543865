<script setup>
    /*
        Drawing component - aimed at both pointer and touch devices.
        Provide a data object and the currently selected item will be used by the editor.
        
        -- Some general notes about decisions --

        The component needs to handle both freedraw and premade shapes, which means that when a shape is currently being
        drawn (user is choosing size/position) the canvas will have to refresh. To avoid implementing a draw history we will
        draw the shapes on a "fake" canvas on top, before it is finalized and actually drawn to the real one (drawing canvas) later.

        The "insert text" function is aimed at being the most user-friendly it can be, and as such we want to keep the same
        text wrapping (including soft wrapping) that the user inputs whenever it's drawn to the canvas. Instead of writing our
        own soft-wrapping text algorithm, this is taken care of by using HTML -> foreignObject -> SVG -> canvas drawImage.
    */
    
    import { ref, watch, computed, nextTick, onMounted } from 'vue';
    import { Cropper } from 'cropper';
    import { app } from 'o365.modules.configs.ts';
    import 'o365.dataObject.extension.FileUpload.ts';
    import FileUtils from 'o365.modules.fileUtils.ts';
    import Translate from 'o365.modules.translate.ts';
    import O365_Confirm from 'o365.controls.confirm.ts';
    import OOverlay from 'o365.vue.components.Overlay.vue';
    import { loadCdnStyle } from 'o365.modules.helpers.js';
    import vResizable from 'o365.vue.directive.resizable.ts';
    import vDraggable from 'o365.vue.directive.draggable.ts';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import logger from 'o365.modules.Logger.ts';

    loadCdnStyle('cropper/style.css');
    loadCdnStyle('cropper/theme.compact.css');

    const { getExtensionFromFileName, isImage, isVideo } = FileUtils;

    const props = defineProps({
        dataObject: {
            type: Object,
            required: true
        },
        offline: {
            type: Boolean,
            required: false
        }
    });

    const availableFields = [];
    props.dataObject.recordSource.fields.map(field => availableFields.push(field.name));
    const requiredFields = ['FileName', 'PrimKey', 'Updated'];
    const missingFields = requiredFields.filter(field => availableFields.indexOf(field) === -1);

    if (missingFields.length > 0) {
        console.warn(`Missing required fields on data object: ${JSON.stringify(missingFields)}`);
    }

    const DRAWMODES = {
        FREEHAND: 'draw_freehand',
        ARROW: 'draw_arrow',
        SHAPE: 'draw_shape',
        TEXT: 'draw_text',
        CROP: 'draw_crop',
    };
    const DRAWMODESHAPES = {
        CIRCLE: 'draw_shape_circle',
        TRIANGLE: 'draw_shape_triangle',
        SQUARE: 'draw_shape_square',
    };
    const CONTROLICONS = {
        [DRAWMODES.FREEHAND]: 'bi-pencil',
        [DRAWMODES.ARROW]: 'bi-arrow-up-right',
        [DRAWMODES.SHAPE]: 'bi-circle',
        [DRAWMODES.TEXT]: 'bi-fonts',
        [DRAWMODES.CROP]: 'bi-crop',
        // -----
        [DRAWMODESHAPES.CIRCLE]: 'bi-circle',
        [DRAWMODESHAPES.TRIANGLE]: 'bi-triangle',
        [DRAWMODESHAPES.SQUARE]: 'bi-square',
    };
    const FONTSIZES = [10, 12, 14, 16, 20, 24, 28, 34, 36, 40];
    const COLORS = {
        BLACK: '#000000',
        WHITE: '#ffffff',
        RED: '#ff0000',
        ORANGE: '#ff7f27',
        YELLOW: '#fff200',
        GREEN: '#22b14c',
        BLUE: '#00a2e8',
        INDIGO: '#3f48cc',
        PURPLE: '#a349a4',
    };
    const STROKEWIDTHS = [1, 2, 3, 4, 5];

    const currentDrawMode = ref(DRAWMODES.FREEHAND);
    const currentDrawShape = ref(DRAWMODESHAPES.CIRCLE);
    const currentFontSize = ref(FONTSIZES[5]);
    const currentDrawColor = ref(COLORS.RED);
    const currentStrokeWidth = ref(STROKEWIDTHS[1]);
    const currentFillState = ref(false);

    const globalImageWidth = ref(0);
    const globalImageHeight = ref(0);
    const surfaceStyleWidth = ref(0);
    const surfaceStyleHeight = ref(0);

    let drawingContext = null;
    let shapesContext = null;
    let didPointerLeave = false;
    const isLoading = ref(false);
    const editorModal = ref(null);
    const drawingModalBody = ref(null);
    const canvasHasChanges = ref(false);
    const isBackgroundApplied = ref(false);
    const isControlsMenuOverflowing = ref(false);
    
    const drawingCanvasContainer = ref(null);
    const drawingSurface = ref(null);
    const shapesSurface = ref(null);
    const drawingCanvasImage = ref(null);
    
    const textareaContainerRef = ref(null);
    const textareaHeight = ref(0);
    const textareaValueRef = ref(null);
    const resizableValue = ref({ height: 150, width: 250, minHeight: 150, minWidth: 250, boundingElement: drawingCanvasContainer.value });
    const isTextareaCreated = ref(false);
    const textareaDragHandle = ref(null);
    const draggableValue = ref({});
    
    const cropperRef = ref(null);
    const cropImageSrc = ref(null);

    const initialized = ref(false);
    const appIdOverride = ref("");
    const databaseIdOverride = ref("");
    const objectStoreIdOverride = ref("");

    const currentImageSrc = computed(() => {
        if (cropImageSrc.value === null) {
            const { PrimKey, FileName, Updated } = props.dataObject.current;
            return `${getLink({ PrimKey, FileName })}?updated=${Updated}`;
        } else {
            return cropImageSrc.value;
        }
    });

    const drawingCanvasContainerStyle = computed(() => {
        return `
            position: absolute;
            width: ${surfaceStyleWidth.value};
            height: ${surfaceStyleHeight.value};
        `;
    });

    const surfaceStyle = computed(() => {
        return `
            width: ${surfaceStyleWidth.value};
            height: ${surfaceStyleHeight.value};
        `;
    });

    const textareaContainerStyle = computed(() => {
        return `
            height: ${Math.max(resizableValue.value.height, textareaHeight.value)}px;
        `;
    });

    const textareaStyle = computed(() => {
        return `
            flex: 1;
            color: ${currentDrawColor.value};
            background: rgba(255, 255, 255, 0.2);
            font-size: ${currentFontSize.value}px;
        `;
    });

    const shouldFontSizeControlShow = computed(() => {
        return [DRAWMODES.TEXT].includes(currentDrawMode.value);
    });

    const shouldLineWidthControlShow = computed(() => {
        return [DRAWMODES.FREEHAND, DRAWMODES.ARROW, DRAWMODES.SHAPE].includes(currentDrawMode.value);
    });

    const shouldColorControlShow = computed(() => {
        return [DRAWMODES.FREEHAND, DRAWMODES.ARROW, DRAWMODES.SHAPE, DRAWMODES.TEXT].includes(currentDrawMode.value);
    });

    const shouldFillStateControlShow = computed(() => {
        return [DRAWMODES.SHAPE].includes(currentDrawMode.value);
    });

    let isPointingDeviceEngaged = false;
    const lastPointerPosition = { x: null, y: null };

    defineExpose({ editorModal });

    const emit = defineEmits(['open', 'close']);

    // watch(() => props.dataObject.current, (newItem) => {
        // if (newItem && isImage(newItem.FileName)) {
            // const img = new Image();
            // img.onload = () => isLoading.value = false;
            // img.src = `${getLink({ PrimKey: newItem.PrimKey, FileName: newItem.FileName })}?updated=${newItem.Updated}`;
        // }
    // });

    watch(currentDrawMode, async (newValue) => {
        if (newValue === DRAWMODES.CROP) {
            cropImageSrc.value = drawingSurface.value.toDataURL();
        }

        const primaryBar = document.querySelector('#O365_DrawingCanvasControlsPrimaryBar');
        const secondaryBar = document.querySelector('#O365_DrawingCanvasControlsSecondaryBar');

        await nextTick();

        isControlsMenuOverflowing.value = (primaryBar.scrollWidth > primaryBar.clientWidth || secondaryBar.scrollWidth > secondaryBar.clientWidth);
    });

    const isMedia = (fileName) => {
        return isImage(getExtensionFromFileName(fileName)) || isVideo(getExtensionFromFileName(fileName));
    }

    const getLink = (row, isDownload = false, originalFile = false) => {
        if(props.offline){
            return getOfflineLink(row, isDownload, originalFile);
        }

        const { PrimKey, FileName } = row;

        let urlBase = '/nt/api';

        if (isMedia(FileName) || originalFile) {
            urlBase = '/nt/api/file';
        }
        
        const viewName = props.dataObject.viewName;

        let fileOrigin = '';

        if (isMedia(FileName)) {
            fileOrigin = isDownload ? 'download' : 'view';
        } else {
            if (isDownload) {
                fileOrigin = originalFile ? 'download' : 'download-pdf';
            } else {
                fileOrigin = 'view-pdf';
            }
        }

        return [urlBase, fileOrigin, viewName, PrimKey, FileName].join('/');
    }

    const getOfflineLink = (row, isDownload, originalFile) => {
        if(!initialized.value) return "";
        
        const { PrimKey, FileName } = row;
        
        let urlBase = '/nt/pwa/api';

        if (isMedia(FileName) || originalFile) {
            urlBase = '/nt/pwa/api/file';
        }

        let fileOrigin = '';

        if (isMedia(FileName)) {
            fileOrigin = isDownload ? 'download' : 'view';
        } else {
            if (isDownload) {
                fileOrigin = originalFile ? 'download' : 'download-pdf';
            } else {
                fileOrigin = 'view-pdf';
            }
        }

        const dataObject = props.dataObject;

        const appId = appIdOverride.value ?? app.id;
        const databaseId = databaseIdOverride.value ?? "DEFAULT";
        const objectStoreId = objectStoreIdOverride.value ?? dataObject.id;


        return `${urlBase}/${fileOrigin}/${appId}/${databaseId}/${objectStoreId}/${PrimKey}/${FileName}`;
    }

    const setDrawMode = (mode) => {
        currentDrawMode.value = mode;

        if (mode !== DRAWMODES.TEXT) {
            textareaValueRef.value = '';
            textareaHeight.value = resizableValue.value.height;
            isTextareaCreated.value = false;
        }
    }

    const setDrawShape = (shape) => {
        currentDrawShape.value = shape;
    }

    const getLocalPointerPosition = (e, isRelativeToContainer = true) => {
        let evt = e;

        // On touchend events the "current touches" (touches) are 0, so changedTouches will be used instead
        // to access the page coordinates
        if (e.touches) {
            evt = e.changedTouches ? e.changedTouches[0] : e.touches[0];
        }

        if (isRelativeToContainer) {
            const geometry = drawingSurface.value.getBoundingClientRect();
            return [evt.clientX - geometry.left, evt.clientY - geometry.top];
        } else {
            return [evt.clientX, evt.clientY];
        }
    }

    const handlePointingDeviceState = (e, isEngaged) => {
        if (isEngaged) {
            [lastPointerPosition.x, lastPointerPosition.y] = getLocalPointerPosition(e);
        }

        if (['mouseup', 'mouseleave', 'touchend'].includes(e.type)) {
            const [drawX, drawY] = getLocalPointerPosition(e);
            
            let shouldDraw = false;

            if (e.type === 'mouseleave') {
                if (e.buttons === 1) {
                    if (!didPointerLeave) shouldDraw = true;
                    didPointerLeave = true;
                }
            } else if (e.type === 'mouseup' && didPointerLeave) {
                didPointerLeave = false;
            } else {
                shouldDraw = true;
            }

            if (currentDrawMode.value === DRAWMODES.ARROW) {
                if (shouldDraw) drawArrow(drawX, drawY, true);
            } else if (currentDrawMode.value === DRAWMODES.SHAPE) {
                switch (currentDrawShape.value) {
                    case DRAWMODESHAPES.CIRCLE:
                        if (shouldDraw) drawEllipse(drawX, drawY, true);
                        break;
                    case DRAWMODESHAPES.TRIANGLE:
                        if (shouldDraw) drawTriangle(drawX, drawY, true);
                        break;
                    case DRAWMODESHAPES.SQUARE:
                        if (shouldDraw) drawRectangle(drawX, drawY, true);
                        break;
                }
            }
        }

        isPointingDeviceEngaged = isEngaged;
    }

    const setContextVariables = (ctx) => {
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.lineWidth = currentStrokeWidth.value;
        ctx.fillStyle = currentDrawColor.value;
        ctx.strokeStyle = currentDrawColor.value;
        ctx.globalCompositeOperation = 'source-over';
    }

    const pointermove = (e) => {
        if (isPointingDeviceEngaged && !isTextareaCreated.value) {
            handleSurfaceDraw(e);
        }
    }

    const pointerdown = (e) => {
        handlePointingDeviceState(e, true);
    }

    const pointerup = (e) => {
        handlePointingDeviceState(e, false);
    }

    const pointerclick = async (e) => {
        const [drawX, drawY] = getLocalPointerPosition(e, false);

        if (currentDrawMode.value === DRAWMODES.TEXT) {
            drawTextarea(drawX, drawY);
        }
    }

    // Source: o365.controls.Cropper.ts
    const dataURItoBlob = (dataURI) => {
        let byteString, mimeString, ab, ia, bb, dataView, blob;

        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = decodeURIComponent(dataURI.split(',')[1]);
        }

        mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        ab = new ArrayBuffer(byteString.length);
        ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        if (window['BlobBuilder'] || window['MSBlobBuilder'] || window['WebKitBlobBuilder'] || window['MozBlobBuilder']) {
            const BlobBuilder = window['BlobBuilder'] || window['MSBlobBuilder'] || window['WebKitBlobBuilder'] || window['MozBlobBuilder'];
            bb = new BlobBuilder();
            bb.append(ab);
            blob = bb.getBlob(mimeString);
        } else if (typeof Blob !== 'undefined') {
            dataView = new DataView(ab);
            blob = new Blob([dataView], { type: 'mimeString' });
        } else {
            return null;
        }

        return blob;
    }

    const resizeSurfaces = async () => {
        const applyResponsiveSide = (width, height) => {
            if (width > height) {
                surfaceStyleWidth.value = '100%';
                surfaceStyleHeight.value = 'auto';
            } else if (width < height) {
                surfaceStyleWidth.value = 'auto';
                surfaceStyleHeight.value = '100%';
            } else {
                surfaceStyleWidth.value = '100%';
                surfaceStyleHeight.value = '100%';
            }
        }

        applyResponsiveSide(globalImageWidth.value, globalImageHeight.value);

        await nextTick();

        const surfaceGeometry = drawingSurface.value.getBoundingClientRect();
        const containerGeometry = drawingModalBody.value.getBoundingClientRect();
        
        // Reapply responsiveness if image overflows container on one of the sides
        if (surfaceGeometry.width > containerGeometry.width) {
            applyResponsiveSide(1, 0);
        }
        
        if (surfaceGeometry.height > containerGeometry.height) {
            applyResponsiveSide(0, 1);
        }
    }

    const drawSurfaceBackground = async (backgroundSrc = null) => {
        drawingContext.setTransform(1, 0, 0, 1, 0, 0);

        if (backgroundSrc !== null) {
            await nextTick();
            
            globalImageWidth.value = drawingCanvasImage.value.naturalWidth;
            globalImageHeight.value = drawingCanvasImage.value.naturalHeight;

            await nextTick();

            drawingContext.drawImage(backgroundSrc, 0, 0, drawingCanvasImage.value.naturalWidth, drawingCanvasImage.value.naturalHeight);
            applySurfacesScale();
    
            isBackgroundApplied.value = true;
            return;
        }

        await new Promise((resolve) => {
            const imageSrc = new Image();

            imageSrc.onload = () => {
                globalImageWidth.value = drawingCanvasImage.value.naturalWidth;
                globalImageHeight.value = drawingCanvasImage.value.naturalHeight;

                nextTick(() => {
                    drawingContext.drawImage(imageSrc, 0, 0, drawingCanvasImage.value.naturalWidth, drawingCanvasImage.value.naturalHeight);
                    applySurfacesScale();
            
                    isBackgroundApplied.value = true;

                    resolve(true);
                });
            }

            imageSrc.src = currentImageSrc.value;
        });
    }

    const handleWindowResize = async () => {
        await resizeSurfaces();

        await nextTick();

        applySurfacesScale();

        const primaryBar = document.querySelector('#O365_DrawingCanvasControlsPrimaryBar');
        const secondaryBar = document.querySelector('#O365_DrawingCanvasControlsSecondaryBar');

        isControlsMenuOverflowing.value = (primaryBar.scrollWidth > primaryBar.clientWidth || secondaryBar.scrollWidth > secondaryBar.clientWidth);
    }

    const applySurfacesScale = () => {
        drawingContext.setTransform(1, 0, 0, 1, 0, 0);
        shapesContext.setTransform(1, 0, 0, 1, 0, 0);

        const geometry = drawingSurface.value.getBoundingClientRect();
        const scaleX = globalImageWidth.value / geometry.width;
        const scaleY = globalImageHeight.value / geometry.height;
        
        drawingContext.scale(scaleX, scaleY);
        shapesContext.scale(scaleX, scaleY);
    }

    const cropImage = async () => {
        const cropResult = cropperRef.value.getResult();

        cropImageSrc.value = cropResult.canvas.toDataURL();
        currentDrawMode.value = DRAWMODES.FREEHAND;
        canvasHasChanges.value = true;

        await nextTick();

        globalImageWidth.value = drawingCanvasImage.value.naturalWidth;
        globalImageHeight.value = drawingCanvasImage.value.naturalHeight;

        await nextTick();

        drawSurfaceBackground();
    }

    const drawFreehand = (drawX, drawY) => {
        setContextVariables(drawingContext);

        drawingContext.beginPath();
            drawingContext.moveTo(lastPointerPosition.x, lastPointerPosition.y);
            drawingContext.lineTo(drawX, drawY);
        drawingContext.stroke();

        canvasHasChanges.value = true;

        lastPointerPosition.x = drawX;
        lastPointerPosition.y = drawY;
    }

    const drawArrow = (drawX, drawY, finalizeDraw = false) => {
        const minLength = 1;
        const dx = drawX - lastPointerPosition.x;
        const dy = drawY - lastPointerPosition.y;

        if (Math.abs(dx) < minLength || Math.abs(dy) < minLength) {
            return;
        }

        const currentContext = finalizeDraw ? drawingContext : shapesContext;
        const angle = Math.atan2(dy, dx);
        const arrowHeadLength = 12;

        shapesContext.clearRect(0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);

        setContextVariables(currentContext);

        currentContext.beginPath();
            currentContext.moveTo(lastPointerPosition.x, lastPointerPosition.y);
            currentContext.lineTo(drawX, drawY);
            currentContext.lineTo(drawX - arrowHeadLength * Math.cos(angle - Math.PI / 6), drawY - arrowHeadLength * Math.sin(angle - Math.PI / 6));
            currentContext.moveTo(drawX, drawY);
            currentContext.lineTo(drawX - arrowHeadLength * Math.cos(angle + Math.PI / 6), drawY - arrowHeadLength * Math.sin(angle + Math.PI / 6));
        currentContext.stroke();

        canvasHasChanges.value = true;
    }

    const drawEllipse = (drawX, drawY, finalizeDraw = false) => {
        const currentContext = finalizeDraw ? drawingContext : shapesContext;

        const radiusX = (drawX - lastPointerPosition.x) * 0.5;
        const radiusY = (drawY - lastPointerPosition.y) * 0.5;
        const centerX = lastPointerPosition.x + radiusX;
        const centerY = lastPointerPosition.y + radiusY;
    
        shapesContext.clearRect(0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);

        setContextVariables(currentContext);

        currentContext.beginPath();
            currentContext.moveTo(centerX + radiusX * Math.cos(0), centerY + radiusY * Math.sin(0));
            
            let step = 0.01;
            let endAngle = Math.PI * 2 - step;
            for(let a = step; a < endAngle; a += step) {
                currentContext.lineTo(centerX + radiusX * Math.cos(a), centerY + radiusY * Math.sin(a));
            }    
        currentFillState.value ? currentContext.fill() : currentContext.stroke();

        canvasHasChanges.value = true;
    }

    const drawTriangle = (drawX, drawY, finalizeDraw = false) => {
        const currentContext = finalizeDraw ? drawingContext : shapesContext;

        shapesContext.clearRect(0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);

        setContextVariables(currentContext);
    
        currentContext.beginPath();
            currentContext.moveTo(lastPointerPosition.x + (drawX - lastPointerPosition.x) / 2, lastPointerPosition.y);
            currentContext.lineTo(lastPointerPosition.x, drawY);
            currentContext.lineTo(drawX, drawY);
            currentContext.lineTo(lastPointerPosition.x + (drawX - lastPointerPosition.x) / 2, lastPointerPosition.y);
        currentFillState.value ? currentContext.fill() : currentContext.stroke();

        canvasHasChanges.value = true;
    }

    const drawRectangle = (drawX, drawY, finalizeDraw = false) => {
        const currentContext = finalizeDraw ? drawingContext : shapesContext;
        
        const rectWidth = drawX - lastPointerPosition.x;
        const rectHeight = drawY - lastPointerPosition.y;

        shapesContext.clearRect(0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);

        setContextVariables(currentContext);

        const args = [lastPointerPosition.x, lastPointerPosition.y, rectWidth, rectHeight];

        currentFillState.value ? currentContext.fillRect(...args) : currentContext.strokeRect(...args);

        canvasHasChanges.value = true;
    }

    const handleTeaxtareaInput = async (e) => {
        if (textareaValueRef.value.trim() !== '') {
            textareaHeight.value = 0;
            
            await nextTick();

            const dragBarHeight = 20;
            const buttonsBarHeight = 35;
            textareaHeight.value = e.target.scrollHeight + dragBarHeight + buttonsBarHeight;

            await nextTick();

            const textareaGeometry = textareaContainerRef.value.getBoundingClientRect();
            const containerGeometry = drawingCanvasContainer.value.getBoundingClientRect();
            if (textareaGeometry.bottom > containerGeometry.bottom) {
                textareaHeight.value = containerGeometry.bottom - textareaGeometry.top;
            }
        } else {
            textareaHeight.value = resizableValue.value.height;
        }
    }

    const drawTextarea = async (drawX, drawY) => {
        if (isTextareaCreated.value) return;
        
        const { right, bottom } = drawingCanvasContainer.value.getBoundingClientRect();

        // Limit the textarea to the container bounding box
        const dx = (drawX + resizableValue.value.width) - right;
        const dy = (drawY + resizableValue.value.height) - bottom;
        
        if (dx > 0) drawX -= dx;
        if (dy > 0) drawY -= dy;

        isTextareaCreated.value = true;

        await nextTick();
        
        draggableValue.value = {
            handle: textareaDragHandle.value,
            boundingElement: drawingCanvasContainer.value,
            initialPosition: { top: drawY, left: drawX },
        };
    }

    const drawSoftWrappedTextToCanvas = (ctx, text, x, y, w, h) => {
        const paragraph = document.createElement('p');
        paragraph.textContent = text;
        const textOutline = currentDrawColor.value === COLORS.WHITE ? .75 : .25;
        paragraph.style.cssText = `
            padding: 0;
            font-family: serif;
            word-wrap: break-word;
            letter-spacing: normal;
            color: ${currentDrawColor.value};
            font-size: ${currentFontSize.value}px;
            line-height: ${currentFontSize.value}px;
            text-shadow: -${textOutline}px ${textOutline}px 0 #000000,
                         ${textOutline}px ${textOutline}px 0 #000000,
                         ${textOutline}px -${textOutline}px 0 #000000,
                         -${textOutline}px -${textOutline}px 0 #000000;
        `;

        // Insert textarea content in foreignObject, then into SVG to keep soft wrapping
        // but also enable canvas context drawImage
        const svgMarkup = `
            <svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg">
                <foreignObject x="0" y="0" width="${w}" height="${h}">
                    <div xmlns="http://www.w3.org/1999/xhtml">${paragraph.outerHTML}</div>
                </foreignObject>
            </svg>`;

        const encodedSvg = svgMarkup.replace(/\n/g, '');

        const img = new Image();

        img.addEventListener('load', () => {
            const dragBarHeight = 20;
            // Gotten by using the incorrect offsets generated by 10px, 24px and 40px font size
            // (-10px, 5px and 25px incorrectly shifted upwards)
            const fontSizeFormula = (1.25 * currentFontSize.value) - 9.72;
            const yOffset = fontSizeFormula - dragBarHeight;
            setContextVariables(ctx);
            ctx.drawImage(img, x, y - yOffset);
        });

        img.src = `data:image/svg+xml,${encodedSvg}`;

        canvasHasChanges.value = true;
    }

    const acceptUserTextInput = (e) => {
        if (e) e.stopPropagation();

        const text = textareaValueRef.value;
        
        if (text.trim() !== '') {
            const textareaRect = textareaContainerRef.value.getBoundingClientRect();
            const parentRect = textareaContainerRef.value.parentNode.getBoundingClientRect();

            const x = textareaRect.x - parentRect.x;
            const y = textareaRect.y - parentRect.y;

            drawSoftWrappedTextToCanvas(drawingContext, text, x, y, textareaRect.width, textareaRect.height);
        }

        cancelUserTextInput();
    }

    const cancelUserTextInput = (e) => {
        if (e) e.stopPropagation();

        textareaValueRef.value = '';
        textareaHeight.value = resizableValue.value.height;
        isTextareaCreated.value = false;
    }

    const handleSurfaceDraw = (e) => {
        const [drawX, drawY] = getLocalPointerPosition(e);

        didPointerLeave = false;

        switch (currentDrawMode.value) {
            case DRAWMODES.FREEHAND:
                drawFreehand(drawX, drawY);
                break;
            case DRAWMODES.ARROW:
                drawArrow(drawX, drawY);
                break;
            case DRAWMODES.SHAPE:
                switch (currentDrawShape.value) {
                    case DRAWMODESHAPES.CIRCLE:
                        drawEllipse(drawX, drawY);
                        break;
                    case DRAWMODESHAPES.TRIANGLE:
                        drawTriangle(drawX, drawY);
                        break;
                    case DRAWMODESHAPES.SQUARE:
                        drawRectangle(drawX, drawY);
                        break;
                }
                break;
        }
    }

    const clearDrawing = async (applyBackground = true) => {
        drawingContext.clearRect(0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        shapesContext.clearRect(0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);

        canvasHasChanges.value = false;
        cropImageSrc.value = null;

        if (applyBackground) drawSurfaceBackground(drawingCanvasImage.value);
    }

    const saveDrawing = () => {
        isLoading.value = true;
        
        const fileName = props.dataObject.current.FileName;
        const format = getExtensionFromFileName(fileName) === 'webp' ? 'image/x-png' : 'image/jpeg';
        
        const blob = dataURItoBlob(drawingSurface.value.toDataURL(format));
        const fileToUpload = new File([blob], fileName);

        props.dataObject.fileUpload.upload({
            files: [fileToUpload],
            onCompleted: () => {
                isLoading.value = false;

                modalHidden();
                editorModal.value.hide();
            }
        }, props.dataObject.current);
    }

    const confirmModalClose = (e) => {
        if (!canvasHasChanges.value) {
            return;
        }

        e.preventDefault();

        const options = {
            title: Translate('Discard Changes'),
            message: Translate('Are you sure you want to discard your drawing?'),
            btnTextOk: Translate('Discard'),
            zIndex: 1060
        };

        O365_Confirm(options).then(() => {
            modalHidden();
            editorModal.value.hide();
        }).catch(e => {
            if (e !== null && !e.Canceled) logger.error(e);
        });
    }

    const modalShown = async () => {
        // Override the default modal close with a confirm dialog (if there has been edits)
        editorModal.value.modal._element.addEventListener('hide.bs.modal', confirmModalClose);
        drawingContext = drawingSurface.value.getContext('2d');
        shapesContext = shapesSurface.value.getContext('2d');

        globalImageWidth.value = drawingCanvasImage.value.naturalWidth;
        globalImageHeight.value = drawingCanvasImage.value.naturalHeight;

        await drawSurfaceBackground();

        window.onresize = handleWindowResize;
        handleWindowResize();

        emit('open');
    }

    const modalHidden = () => {
        editorModal.value.modal._element.removeEventListener('hide.bs.modal', confirmModalClose);
        currentDrawMode.value = DRAWMODES.FREEHAND;
        isBackgroundApplied.value = false;
        clearDrawing(false);

        emit('close');
    }

    const initalize = async () => {
        if (!props.offline) {
            return;
        }

        await import('o365.dataObject.extension.Offline.ts');

        const dataObject = await props.dataObject.offline;

        appIdOverride.value = await dataObject.appIdOverride;
        databaseIdOverride.value = await dataObject.databaseIdOverride;
        objectStoreIdOverride.value = await dataObject.objectStoreIdOverride;

        initialized.value = true;
    }

    onMounted(() => {
        initalize();
    });
</script>

<template v-if="(props.offline && initialized) || !props.offline">
    <OModal ref="editorModal" @shown="modalShown" @hidden="modalHidden">
        <OOverlay v-if="isLoading" />

        <OMediaQueryProvider v-slot="{ isMobile }">
            <div class="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-lg-down overflow-hidden d-flex" :style="{ 'height:95vh': !isMobile }">
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center justify-content-between" :class="{ 'py-2 px-3': isMobile }">
                        <h4 class="m-0" :class="{ 'fs-5': isMobile }">{{$t('Image Editor')}}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>

                    <div ref="drawingModalBody" class="modal-body d-flex align-items-center justify-content-center overflow-hidden p-0" style="user-select:none;">
                        <div ref="drawingCanvasContainer"
                            @click="(e) => pointerclick(e)"
                            @mousedown="(e) => pointerdown(e)"
                            @touchstart="(e) => pointerdown(e)"
                            @mousemove="(e) => pointermove(e)"
                            @touchmove="(e) => pointermove(e)"
                            @mouseup="(e) => pointerup(e)"
                            @mouseleave="(e) => pointerup(e)"
                            @touchend="(e) => pointerup(e)"
                            :style="drawingCanvasContainerStyle">
                            <Transition>
                                <canvas v-show="isBackgroundApplied"
                                    ref="drawingSurface"
                                    :width="globalImageWidth"
                                    :height="globalImageHeight"
                                    :style="surfaceStyle">
                                </canvas>
                            </Transition>

                            <canvas ref="shapesSurface"
                                :width="globalImageWidth"
                                :height="globalImageHeight"
                                style="position:absolute;left:0;"
                                :style="surfaceStyle">
                            </canvas>

                            <div ref="textareaContainerRef"
                                v-if="isTextareaCreated"
                                v-resizable="resizableValue"
                                v-draggable="draggableValue"
                                class="d-flex flex-column rounded"
                                style="border:1px solid #000000;"
                                :style="textareaContainerStyle">
                                <div ref="textareaDragHandle" class="O365_TextareaDragBar bg-secondary border-bottom border-black rounded-top w-100" style="height:20px;"></div>
                                
                                <OTextArea class="O365_CanvasTextarea" :style="textareaStyle" v-model="textareaValueRef" @input="handleTeaxtareaInput" />

                                <div class="w-100 text-end" style="border-top:1px solid #000000;background:rgba(255, 255, 255, 0.2);">
                                    <div class="btn-group" role="group">
                                        <button class="btn btn-primary border-top-0 border-bottom-0 border-end-0 rounded-0" @click="acceptUserTextInput">
                                            <i class="bi bi-check-lg"></i>
                                        </button>
                                        
                                        <button class="btn btn-secondary border-top-0 border-bottom-0 border-end-0 rounded-0" @click="cancelUserTextInput">
                                            <i class="bi bi-x-lg"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <Cropper v-if="currentDrawMode === DRAWMODES.CROP"
                                ref="cropperRef"
                                :src="currentImageSrc"
                                class="w-100 h-100 overflow-hidden"
                                style="z-index:20;position:absolute;top:0;" />
                        </div>

                        <img :src="currentImageSrc" ref="drawingCanvasImage" class="d-none" />
                    </div>

                    <div class="modal-footer" :class="{ 'p-0': isMobile }">
                        <div class="d-flex justify-content-between w-100" :class="{ 'm-0': isMobile }">
                            <div class="d-flex flex-column justify-content-between" :class="{ 'p-2 pe-0': isMobile }" style="min-width:0;">
                                <div id="O365_DrawingCanvasControlsSecondaryBar" class="O365_VisuallyHideScrollbars d-flex align-items-center gap-3 mb-2" style="overflow-x:auto;">
                                    <div v-if="currentDrawMode === DRAWMODES.SHAPE" class="btn-group" role="group">
                                        <template v-for="shape in DRAWMODESHAPES">
                                            <input :id="`O365_DrawingCanvas_${shape}`" type="radio" class="btn-check" name="O365_DrawingCanvasControlsSecondary" :checked="currentDrawShape === shape" />
                                            <label class="btn btn-outline-primary fs-5" :for="`O365_DrawingCanvas_${shape}`" @click="setDrawShape(shape)">
                                                <i :class="`bi ${CONTROLICONS[shape]}`"></i>
                                            </label>
                                        </template>
                                    </div>

                                    <div class="btn-group gap-2" style="flex-shrink:0;" role="group">
                                        <div v-if="shouldFontSizeControlShow" class="dropup dropup-start">
                                            <button class="btn btn-primary fs-5" data-bs-toggle="dropdown" data-bs-config='{ "popperConfig": { "strategy": "fixed" } }'>
                                                <i class="bi bi-type me-1"></i>
                                                {{`${currentFontSize}px`}}
                                            </button>

                                            <ul class="dropdown-menu">
                                                <li class="dropdown-header text-muted fs-6 px-2 py-1">{{$t('Font Size')}}</li>
                                                
                                                <li><hr class="dropdown-divider"></li>

                                                <ul class="ps-0" style="max-height:150px;overflow-y:auto;">
                                                    <li class="dropdown-item"
                                                        :style="`${currentFontSize === size ? 'color:var(--bs-blue)' : ''};`"
                                                        v-for="size in FONTSIZES"
                                                        @click="currentFontSize = size">
                                                        {{`${size}px`}}
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>

                                        <div v-if="shouldLineWidthControlShow" class="dropup dropup-start">
                                            <button class="btn btn-primary fs-5" data-bs-toggle="dropdown" data-bs-config='{ "popperConfig": { "strategy": "fixed" } }'>
                                                <i class="bi bi-arrows-expand me-1"></i>
                                                {{`${currentStrokeWidth}px`}}
                                            </button>

                                            <ul class="dropdown-menu">
                                                <li class="dropdown-header text-muted fs-6 px-2 py-1">{{$t('Line Width')}}</li>
                                                
                                                <li><hr class="dropdown-divider"></li>

                                                <ul class="ps-0">
                                                    <li class="dropdown-item"
                                                        :style="`${currentStrokeWidth === size ? 'color:var(--bs-blue)' : ''};`"
                                                        v-for="size in STROKEWIDTHS"
                                                        @click="currentStrokeWidth = size">
                                                        {{`${size}px`}}
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>
                                        
                                        <div v-if="shouldColorControlShow" class="dropup dropup-start">
                                            <button class="btn btn-primary fs-5" data-bs-toggle="dropdown" data-bs-config='{ "popperConfig": { "strategy": "fixed" } }'>
                                                <i class="bi bi-palette-fill" :style="`color:${currentDrawColor};`"></i>
                                            </button>

                                            <ul class="dropdown-menu px-1">
                                                <span class="dropdown-header text-muted fs-6 px-2 py-1">{{$t('Color Picker')}}</span>
                                                
                                                <span><hr class="dropdown-divider"></span>
                                                
                                                <li class="O365_ColorDropdown p-0">
                                                    <div class="O365_ColorChoice border border-2 border-dark-subtle"
                                                        :class="{ O365_ColorPicked: currentDrawColor === COLORS[name] }"
                                                        :style="`background-color:${color};`"
                                                        v-for="(color, name) in COLORS"
                                                        @click="currentDrawColor = COLORS[name]">
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div v-if="shouldFillStateControlShow">
                                            <input type="checkbox" class="btn-check" id="O365_DrawingCanvasFillStateControl" v-model="currentFillState">
                                            <label class="btn btn-outline-primary fs-5" for="O365_DrawingCanvasFillStateControl">
                                                <i class="bi bi-paint-bucket"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div id="O365_DrawingCanvasControlsPrimaryBar" class="O365_VisuallyHideScrollbars btn-group" style="flex-shrink:0;overflow-x:auto;" role="group">
                                    <template v-for="drawmode in DRAWMODES">
                                        <input :id="`O365_DrawingCanvas_${drawmode}`" type="radio" class="btn-check" name="O365_DrawingCanvasControlsPrimary" :checked="currentDrawMode === drawmode" />
                                        <label class="btn btn-outline-primary fs-5" style="flex-grow:0;" :for="`O365_DrawingCanvas_${drawmode}`" @click="setDrawMode(drawmode)">
                                            <i :class="`bi ${CONTROLICONS[drawmode]}`"></i>
                                        </label>
                                    </template>
                                </div>
                            </div>

                            <div class="d-flex flex-column justify-content-between ps-3" :class="{ 'p-2': isMobile, 'O365_ControlsMenuOverflow': isControlsMenuOverflowing }">
                                <template v-if="currentDrawMode !== DRAWMODES.CROP">
                                    <button class="btn btn-primary text-nowrap mb-3" @click="saveDrawing" :disabled="!canvasHasChanges">
                                        <i class="bi bi-save me-2"></i>
                                        {{$t('Save')}}
                                    </button>

                                    <button class="btn btn-outline-secondary text-nowrap" @click="clearDrawing" :disabled="!canvasHasChanges">
                                        <i class="bi bi-x-lg me-2"></i>
                                        {{$t('Clear')}}
                                    </button>
                                </template>

                                <template v-else>
                                    <button class="btn btn-primary text-nowrap mb-3" @click="cropImage">
                                        <i class="bi bi-crop me-2"></i>
                                        {{$t('Crop')}}
                                    </button>

                                    <button class="btn btn-outline-secondary" @click="currentDrawMode = DRAWMODES.FREEHAND">
                                        {{$t('Cancel')}}
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OMediaQueryProvider>
    </OModal>
</template>

<style scoped>
    .O365_CanvasTextarea {
        padding: 0;
        resize: none;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        font-family: serif;
        line-height: normal;
        box-sizing: border-box;
        letter-spacing: normal;
        background: transparent;
        text-shadow: -.5px .5px 0 #000000,
                     .5px .5px 0 #000000,
                     .5px -.5px 0 #000000,
                     -.5px -.5px 0 #000000;
    }

    .O365_ControlsMenuOverflow {
        box-shadow: var(--o365-grid-shadow-left);
    }

    .O365_ColorDropdown {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .O365_ColorChoice {
        cursor: pointer;
        width: 45px;
        height: 30px;
        margin: .5rem;
        border-radius: 5px;
    }

    .O365_ColorPicked {
        border-color: #ffb700 !important;
    }

    .O365_VisuallyHideScrollbars {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .O365_VisuallyHideScrollbars::-webkit-scrollbar {
        display: none;
    }
</style>
